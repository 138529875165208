import { defineStore } from "pinia"
import { ref } from "vue"

import type { AuthErrorSchema } from "@fola/schemas/auth"
import type client from "@/core/rpc"
import type { RpcResult } from "@/core/rpc"

/**
 * Store information about the current user's permissions and their authentication status.
 */
export const useAuthStore = defineStore("auth", () => {
	const username = ref<string | null>(null)
	const isAuthenticated = ref<boolean>(false)
	const isAdmin = ref<boolean>(false)
	const errorMessage = ref<AuthErrorSchema["error"] | null>(null)

	function logout(error?: AuthErrorSchema["error"]) {
		username.value = null
		isAuthenticated.value = false
		isAdmin.value = false
		errorMessage.value = error ?? null
	}

	function login(loginInfo: RpcResult<typeof client.login.mutate>) {
		username.value = loginInfo.username
		isAuthenticated.value = true
		isAdmin.value = loginInfo.isAdmin
		errorMessage.value = null
	}

	return { username, isAuthenticated, errorMessage, isAdmin, login, logout }
})
