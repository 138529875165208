import { computed, shallowRef, triggerRef } from "vue"
import { defineStore } from "pinia"

import { omit, oSet } from "@fola/utils/fn"
import type { BoardEditorData as BaseBoardData } from "@fola/schemas/folaBoard"
import type { CourseEditorData as BaseCourseData } from "@fola/schemas/course"
import type { PartialExcept } from "@fola/utils/types"

import { createBoard as _createBoard } from "@fola/state/folaBoard/utils"
import client from "@/core/rpc"
import { generateId } from "@/core/objectUtils"
import { useAuthStore } from "@/auth/store"

enum Order {
	ASC,
	DESC,
}

type Sortable = { updatedAt: string | null; title: string }

interface CourseEditorData extends BaseCourseData {
	locked: boolean
}

interface BoardEditorData extends BaseBoardData {
	locked: boolean
}

const cmpUpdateTimeThenTitle = (order: Order) => (a: Sortable, b: Sortable) => {
	const now = new Date()
	const aDate = (a.updatedAt && new Date(a.updatedAt)) || now
	const bDate = (b.updatedAt && new Date(b.updatedAt)) || now

	const r = aDate < bDate ? -1 : aDate > bDate ? 1 : a.title.localeCompare(b.title)
	return order === Order.ASC ? r : -r
}

export const useDashboardStore = defineStore("dashboard", () => {
	const auth = useAuthStore()

	const _courses = shallowRef<Map<string, CourseEditorData>>(new Map())
	const _boards = shallowRef<Map<string, BoardEditorData>>(new Map())
	const _sharedWithMe = shallowRef<Map<string, CourseEditorData | BoardEditorData>>(new Map())

	/** A sorted list of courses for the current user. */
	const courses = computed(() => Array.from(_courses.value.values()).toSorted(cmpUpdateTimeThenTitle(Order.DESC)))

	/** A sorted list of boards for the current user. */
	const boards = computed(() => Array.from(_boards.value.values()).toSorted(cmpUpdateTimeThenTitle(Order.DESC)))

	/** A sorted list of courses and boards shared with the current user. */
	const sharedWithMe = computed(() =>
		Array.from(_sharedWithMe.value.values()).toSorted(cmpUpdateTimeThenTitle(Order.DESC)),
	)

	/** Fetches all courses and boards for the current user into the store. */
	async function fetchAll() {
		if (!auth.username) return
		const newCourses = new Map<string, CourseEditorData>()
		const newBoards = new Map<string, BoardEditorData>()
		const newSharedWithMe = new Map<string, CourseEditorData | BoardEditorData>()

		const [serverCourses, serverBoards] = await Promise.all([
			client.courseListForUser.query(),
			client.folaBoardListForUser.query(),
		])

		for (const course of serverCourses) {
			const _course = oSet(course, "locked", false)
			if (course.members[auth.username].role == "owner") {
				newCourses.set(course.id, _course)
			} else {
				newSharedWithMe.set(course.id, _course)
			}
		}

		for (const board of serverBoards) {
			const _board = oSet(board, "locked", false)
			if (_board.members[auth.username].role == "owner") {
				newBoards.set(board.id, _board)
			} else {
				newSharedWithMe.set(board.id, _board)
			}
		}

		_courses.value = newCourses
		_boards.value = newBoards
		_sharedWithMe.value = newSharedWithMe
	}

	/** Creates a new course and adds it to the store after the server has confirmed. */
	async function createCourse(data: Omit<CourseEditorData, "id" | "locked">) {
		const tmpId = generateId()
		_courses.value.set(tmpId, Object.assign(data, { locked: true, id: tmpId }))
		triggerRef(_courses)

		const course = oSet(omit(await client.courseCreate.mutate(data), ["folaBoards"]), "locked", false)
		_courses.value.delete(tmpId)
		_courses.value.set(course.id, course)
		triggerRef(_courses)
	}

	/** Creates a new board and adds it to the store after the server has confirmed. */
	async function createBoard(dataOrDemo: "demo" | Omit<BoardEditorData, "id" | "locked">) {
		const tmpId = generateId()
		const data = dataOrDemo == "demo" ? _createBoard(tmpId) : dataOrDemo
		_boards.value.set(tmpId, Object.assign(data, { locked: true, id: tmpId }))
		triggerRef(_boards)

		const board = await (dataOrDemo == "demo"
			? client.folaBoardCreateDemoboard.mutate()
			: client.folaBoardCreate.mutate(data))
		_boards.value.delete(tmpId)
		_boards.value.set(board.id, oSet(board, "locked", false))
		triggerRef(_boards)
	}

	/** Updates a board and adds it to the store after the server has confirmed. */
	function updateCourse(newData: PartialExcept<CourseEditorData, "id">) {
		const oldData = _courses.value.get(newData.id)
		if (!oldData) return
		const data: CourseEditorData = { ...oldData, ...newData, locked: false }
		_courses.value.set(data.id, data)
		if (_sharedWithMe.value.has(data.id)) _sharedWithMe.value.set(data.id, data)
		triggerRef(_courses)
		triggerRef(_sharedWithMe)
	}

	/** Updates a board and adds it to the store after the server has confirmed. */
	function updateBoard(newData: PartialExcept<BoardEditorData, "id">) {
		const oldData = _boards.value.get(newData.id)
		if (!oldData) return
		const data: BoardEditorData = { ...oldData, ...newData, locked: false }
		_boards.value.set(data.id, data)
		if (_sharedWithMe.value.has(data.id)) _sharedWithMe.value.set(data.id, data)
		triggerRef(_boards)
		triggerRef(_sharedWithMe)
	}

	/** Delete a course */
	async function deleteCourse(id: string) {
		await client.courseDelete.mutate({ id })
		_courses.value.delete(id)
		triggerRef(_courses)
	}

	/** Delete a board */
	async function deleteBoard(id: string) {
		await client.folaBoardDelete.mutate({ id })
		_boards.value.delete(id)
		triggerRef(_boards)
	}

	async function removeShare(id: string, type: "course" | "board") {
		if (!auth.username) return
		await (type == "board"
			? client.folaBoardRemoveMember.mutate({ boardId: id, username: auth.username })
			: client.courseRemoveMember.mutate({ courseId: id, username: auth.username }))
		_sharedWithMe.value.delete(id)
		triggerRef(_sharedWithMe)
	}

	return {
		courses,
		boards,
		sharedWithMe,

		fetchAll,
		createCourse,
		createBoard,
		updateCourse,
		updateBoard,
		deleteCourse,
		deleteBoard,
		removeShare,
	}
})
