<!--
This is the main component of the application. It holds the app shell and the router view.
It it also responsible for registering global event handlers that have to be loaded after
the rest of the application, like the authentication check.
-->

<script setup lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */

import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from "vue"
import { RouterLink, RouterView, useRoute, useRouter } from "vue-router"
import { storeToRefs } from "pinia"

import ChevronBarUpIcon from "bootstrap-icons/icons/chevron-bar-up.svg"
import HomeIcon from "bootstrap-icons/icons/house-heart.svg"
import PreferencesIcon from "bootstrap-icons/icons/gear.svg"

import * as AuthService from "./auth/services"
import { persistStoreToLocalStorage, restoreStoreFromLocalStorage } from "./core/storeUtils"
import BsDropdown from "./core/components/BsDropdown.vue"
import FolaNotification from "./core/components/FolaNotification.vue"
import { logout } from "./auth/services"
import { useAuthStore } from "./auth/store"
import { useI18n } from "./core/i18nStore"
import { useNotificationStore } from "./core/notificationStore"

const i18n = useI18n()
const route = useRoute()
const router = useRouter()
const noti = useNotificationStore()
;(window as any).noti = noti

/*
 * Everytime the application is focused, the authentication status is checked,
 * so we can show a login screen if the session has expired.
 */
const authStore = useAuthStore()
const { isAuthenticated } = storeToRefs(authStore)

restoreStoreFromLocalStorage(authStore)
persistStoreToLocalStorage(authStore)

watch(isAuthenticated, (isAuthenticated) => {
	if (!isAuthenticated) {
		// the router push cannot be in the login service, because it would cause a
		// circular dependency: login service -> router -> login view -> login service
		router.push(`/login?next=${route.path}`)
	}
})

async function checkLoggedIn() {
	if (route.path !== "/login") {
		const loggedIn = await AuthService.checkLoggedIn()
		if (!loggedIn) {
			authStore.logout("notLoggedIn")
		}
	}
}

// UI state (simple bootstrap js replacement)
const navCollapse = ref(true)
const showToTop = ref(false)
const topRef = ref<HTMLElement>()

// this is used to hide/show the "to top" button when the user scrolls
let toTopObserver = new IntersectionObserver(
	(entries) => {
		showToTop.value = !entries[0].isIntersecting
	},
	{ rootMargin: "64px" },
)

function scrollToTop() {
	topRef.value?.scrollIntoView({ behavior: "smooth" })
}

// connect the things: scroll to top button, authentication check
onMounted(() => {
	// scroll to top button
	toTopObserver.observe(topRef.value!)
	checkLoggedIn()
	window.addEventListener("focus", checkLoggedIn)
})

onBeforeMount(() => {
	// matomo script
	if (!document.getElementById("matomo-script")) {
		// add Matomo opt-out script
		const script = document.createElement("script")
		script.id = "matomo-script"
		script.src =
			"https://ssl.studiumdigitale.uni-frankfurt.de/piwik/index.php?module=CoreAdminHome&action=optOutJS&divId=matomo-opt-out&language=auto&showIntro=1"
		script.async = true
		script.crossOrigin = "use-credentials" // or 'anonymous' if needed
		document.body.appendChild(script)
		console.log("Matomo opt-out script added")
	}
})

onBeforeUnmount(() => {
	toTopObserver.disconnect()
	window.removeEventListener("focus", checkLoggedIn)
})

// helpers for playing around on the dev console
// these helpers allow you to access the store and the i18n object from the console
;(window as any).auth = AuthService
;(window as any).i18n = i18n
</script>

<template>
	<!-- an empty div that is used as a scroll target for the "scroll up" button -->
	<div id="top" ref="topRef" class="position-absolute"></div>

	<!-- Navigation bar -->

	<header class="shadow navbar navbar-expand-lg bg-primary navbar-dark sticky-top">
		<nav class="container-fluid">
			<RouterLink to="/">
				<div class="navbar-brand">{{ i18n.s?.core.title }}</div>
			</RouterLink>
			<button
				class="navbar-toggler"
				type="button"
				@click="navCollapse = !navCollapse"
				aria-controls="navbar-collapse"
				:aria-expanded="navCollapse"
				aria-label="Toggle navigation"
			>
				<span class="navbar-toggler-icon"></span>
			</button>

			<!-- This part of the navigation bar is collapsed in the mobile view. -->
			<div class="navbar-collapse collapse" :class="{ show: !navCollapse }" id="navbar-collapse">
				<ul class="navbar-nav me-auto mb-2 mt-2 mb-lg-0 mt-lg-0">
					<!-- <li class="nav-item">
						<RouterLink to="/" class="nav-link" active-class="active" aria-current-value="page">
							<span class=""><HomeIcon /> {{ i18n.s?.core.home }}</span>
						</RouterLink>
					</li> -->

					<!--
						These links are only shown to logged in users.

						The <template> tag gets removed from the DOM by vue. It is used here because we needed
						a conditional around multiple elements and it would have been impractical to add `v-if`
						to every single element.
					-->
					<!-- <template v-if="authStore.isAuthenticated">
						<li class="nav-item">
							<RouterLink
								:to="'/s/' + boardId"
								class="nav-link"
								active-class="active"
								aria-current-value="page"
								:key="route.fullPath"
								@click.prevent="createdBoardId()"
							>
								{{ i18n.s?.folaBoard.newDemoboard }}
							</RouterLink>
						</li>
					</template> -->
					<!-- <li class="nav-item">
						<RouterLink to="/about" class="nav-link" active-class="active" aria-current-value="page">
							{{ i18n.s?.core.about }}
						</RouterLink>
					</li> -->
				</ul>

				<!-- Nav Items that are shown to the right of the Nav Bar. They are mostly about the user. -->
				<ul class="navbar-nav mb-2 mt-2 mb-lg-0 mt-lg-0 ms-auto text-end">
					<li class="nav-item ms-auto" v-if="authStore.isAuthenticated">
						<RouterLink to="/personal-dashboard" class="nav-link" active-class="active" aria-current-value="page">
							<span class=""><HomeIcon /> {{ i18n.s?.personalDashboard.title }}</span>
						</RouterLink>
					</li>
					<!-- <li>
						<RouterLink
							v-if="authStore.isAuthenticated"
							to="/cardCollection"
							class="nav-link"
							active-class="active"
							aria-current-value="page"
							><span><StackIcon /> {{ i18n.s?.cardCollection.title }}</span></RouterLink
						>
					</li> -->
					<li class="nav-item">
						<RouterLink
							v-if="authStore.isAuthenticated"
							to="/me"
							class="nav-link"
							active-class="active"
							aria-current-value="page"
							><span class=""><PreferencesIcon /> {{ i18n.s?.auth.userSettings }}</span>
						</RouterLink>
						<RouterLink v-else to="/login" class="nav-link" active-class="active" aria-current-value="page">{{
							i18n.s?.auth.login
						}}</RouterLink>
					</li>

					<li class="nav-item" v-if="authStore.isAuthenticated">
						<RouterLink to="/" class="nav-link" @click="logout" aria-current-value="page">{{
							i18n.s?.core.logout
						}}</RouterLink>
					</li>

					<li class="nav-item ms-auto">
						<BsDropdown btn-type="button" btn-class="nav-link btn btn-link" menu-class="dropdown-menu-end">
							<template #button-inner>{{ i18n.lang.toUpperCase() }}</template>
							<template #items>
								<li class="dropdown-item" @click="i18n.changeLanguage('de')">Deutsch</li>
								<li class="dropdown-item" @click="i18n.changeLanguage('en')">English</li>
								<li class="dropdown-item" @click="i18n.changeLanguage('nl')">Nederlands</li>
							</template>
						</BsDropdown>
					</li>
				</ul>
			</div>
		</nav>
	</header>

	<!-- Main View, depends on current route -->
	<RouterView v-slot="{ Component }">
		<transition name="route" mode="out-in">
			<Component :is="Component" :key="route.fullPath.includes('/s/') ? route.fullPath : undefined" />
		</transition>
	</RouterView>

	<!-- Footer -->
	<footer class="shadow bg-primary px-2">
		<!-- <div v-if="config.env != 'production'">{{ config.env }}</div> -->
		<nav class="footer-nav">
			<RouterLink to="/about" active-class="active" aria-current-value="page" class="nav-link">
				{{ i18n.s?.core.about }}
			</RouterLink>
			<RouterLink to="/privacypolicy" active-class="active" aria-current-value="page" class="nav-link">
				{{ i18n.s?.core.privacyPolicy }}
			</RouterLink>
			<RouterLink to="/legalnotice" active-class="active" aria-current-value="page" class="nav-link">
				{{ i18n.s?.core.legalnotice }}
			</RouterLink>
			<RouterLink to="/accessibility" active-class="active" aria-current-value="page" class="nav-link">
				{{ i18n.s?.core.accessibility }}
			</RouterLink>
		</nav>
	</footer>

	<!-- the "scroll up" thingy in the bottom right -->
	<div
		class="to-top btn btn-primary rounded-circle bg-secondary border-secondary shadow"
		:class="{ show: showToTop }"
		@click="scrollToTop"
	>
		<a href="#" class="text-black"><ChevronBarUpIcon /></a>
	</div>

	<!-- notifications -->
	<aside class="toast-container position-fixed bottom-0 end-0 pb-5 p-3">
		<FolaNotification
			v-for="notification in noti.notifications"
			:key="notification.id"
			:notification="notification"
			@close="noti.close(notification.id)"
		/>
	</aside>

	<!-- Matomo Opt-Out -->
	<div id="matomo-opt-out" style="display: none"></div>
</template>

<style scoped lang="scss">
footer {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	justify-content: space-around;

	@media (max-width: 600px) {
		flex-direction: column;
		align-items: center;
	}
}

.footer-nav {
	display: contents;
}

.to-top {
	align-items: center;
	bottom: 1rem;
	display: flex;
	height: 3rem;
	justify-content: center;
	position: fixed;
	right: 1rem;
	transform: translateY(4rem);
	transition: transform 0.2s ease-in-out;
	width: 3rem;
	z-index: 1000;
	right: 2rem;

	a {
		display: contents;
	}

	&.show {
		transform: translateY(0);
	}
}
.dropdown-menu-end {
	right: 0;
	left: auto;
}
</style>
